import { html } from "htm/preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { generateId, getPage, Pagination, t, useInput } from "./core";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, can, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Button, Modal } from "react-bootstrap";
import { ActivityForm } from "./activites";
import { Contact } from "./contacts";
import { IdentifierTypes } from "./entities";
import AsyncSelect from "react-select/async";
import { Department } from "./establishments-types";
import { Office, User } from "./users";


export interface VisitorInfo {
    name: string;
    identifier: string;
    identifier_type: string;
}


export function AddForm({ data: object, formRef, onAdded }: { data?: VisitorLog, formRef: any, onAdded: any }) {

    const [state, dispatch] = useSession();

    let arrived_at = '';
    try {
        arrived_at = new Date().toLocaleString().slice(arrived_at.length - 8, arrived_at.length - 3);
        console.log('ARRIVED AT', arrived_at);
    } catch (e) {
        console.error(e);
    }

    const [officesOptions, setOfficesOptions] = useState<any[]>([]);
    const [form, setForm] = useState<Partial<VisitorLog>>({
        date: new Date().toISOString().split('T')[0],
        arrived_at,
        office_id: state.office ? { label: state.office.name, value: state.office.id } : undefined,
    });

    const set = (key: keyof VisitorLog) => (value: any) => {
        setForm({ ...form, [key]: value.target ? value.target.value : value });
    }

    const [entities, setEntities] = useState<any[]>([]);


    const [validated, setValidated] = useState(false);

    const [visitor, setVisitor] = useState<any>();

    const activityFormRef = useRef(null);
    const [showAddGuest, setShowAddGuest] = useState(false);

    const onGuestAdded = (guest: any) => {
        setVisitor(guest);
        setShowAddGuest(false);
    };
    useEffect(() => {
        Api.search('/offices', '', 1, {}, state.token).then((res) => {
            setOfficesOptions(res.data.map((it: any) => ({ label: it.name, value: it.id })));
        });
    }, []);


    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            if (!visitor) {
                return alert('Please select a visitor');
            }


            const newObject = { ...form };

            Object.keys(newObject).forEach((key) => {
                if (newObject[key]?.value) {
                    newObject[key] = newObject[key].value;
                }
            });

            if (newObject.arrived_at) {
                newObject.arrived_at += ':00';
            }

            delete newObject.departed_at;

            newObject.purpose = newObject.purpose || '';

            if (visitor.guest) {
                newObject.guest_id = visitor.id;
            } else {
                newObject.visitor_user_id = visitor.id;
            }

            if (object?.id) {

                Api.patch('/visitors-logs', object.id, newObject, state.token)
                    .then((entity) => {
                        toast.success(t('Log updated successfully'));
                        //route('/entities/' + entity.id);
                    });

            } else {
                Api.post('/visitors-logs', newObject, state.token).then((res) => {
                    toast.success(t('Log added successfully'));
                    if (onAdded) {
                        onAdded(res);
                    } else {
                        // route('/entities/' + entity.id);
                    }
                });
            }

        }

    };


    const loadUsersOptions = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadDepartmentsOptions = (inputValue: string) =>
        Api.search('/departments', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });


    const loadVisitorsOptions = (inputValue: string) =>
        Promise.all([
            Api.search('/users', inputValue, 1, {}, state.token),
            Api.search('/guests', inputValue, 1, {}, state.token)
        ]).then(([users, guests]) => {
            guests.data.forEach(it => it.guest = true);
            return users.data.concat(guests.data);
        });
    const handleCreateGuest = (inputValue: string) => {
        setShowAddGuest(true);
    };

    return html`<form ref=${formRef} id="entity-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Date')}</label>
        <input type="date"  value=${form.date} onInput=${set('date')} class="form-control" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Arrival Time')}</label>
        <input type="time"  value=${form.arrived_at} onInput=${set('arrived_at')} class="form-control" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="name">${t('Badge Number')}</label>
        <input type="text" class="form-control" id="name" value=${form.badge_number} onInput=${set('badge_number')} required />
        <div class="invalid-feedback">${t('Badge Number')} ${t('is required')}</div>
    </div>
        <div class="col-lg-3">
        <label class="form-label" for="name">${t('Building Level')}</label>
        <input type="number" class="form-control" id="name" value=${form.level} onInput=${set('level')} required />
        <div class="invalid-feedback">${t('Building Level')} ${t('is required')}</div>
    </div>
    <div class="col-lg-6">
    <label class="form-label" for="name">${t('Visitor')}</label>
<${AsyncCreatableSelect} 
    getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.name + ` (${t(option.guest ? 'Guest' : 'Employee')})`} getOptionValue=${option => option.id} cacheOptions defaultOptions
isClearable onCreateOption=${handleCreateGuest} loadOptions=${loadVisitorsOptions}  value=${visitor} onChange="${setVisitor}"
    className="rs form-control ${validated && (visitor ? 'is-valid' : 'is-invalid')}" />
</div>
    ${visitor ? html`<div class="col-lg-6">
                <label class="form-label" for="name">${t('Identifier')}</label>

        <div class="form-control">
        <${IdentityTypeIcon} type=${visitor.identifier_type}/>
        ${visitor.identifier}
        </div>
    </div>` : html`<div class="col-lg-6"></div>`}

    
    <!--<div class="col-lg-6">
        <label class="form-label" for="type">${t('Departure Time')}</label>
        <input type="time"  value=${form.departed_at} onInput=${set('departed_at')} class="form-control" />
    </div>-->
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Establishment')}</label>
        <${Select} value=${form.office_id}
        options=${officesOptions} onChange=${set('office_id')}
        className="rs form-control ${validated && (form.office_id ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Department')}</label>
        <${AsyncSelect}  cacheOptions defaultOptions isClearable loadOptions=${loadDepartmentsOptions}  value=${form.department_id} onChange="${set('department_id')}"
        className="rs form-control ${validated && (form.department_id ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Agent')}</label>
        <${AsyncSelect}  cacheOptions defaultOptions isClearable loadOptions=${loadUsersOptions}  value=${form.user_id} onChange="${set('user_id')}" className="rs form-control" />
    </div>
 <div class="col-lg-12">
        <label class="form-label" for="type">${t('Purpose')}</label>
                        <textarea value=${form.purpose} class="form-control" rows="2" onInput=${set('purpose')} ></textarea>
</div>
    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="entity-submit" class="btn btn-primary" type="submit"> ${object?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>
<${AddGuestModal} show=${showAddGuest} setShow=${setShowAddGuest} onAdded=${onGuestAdded} />

`;
}


export interface NewVisitorLog {
    date: string;
    badge_number: string;
    arrived_at: string;
    departed_at: string;
    office_id: number;
    department_id: number;
    user_id: number;
    purpose: string;
    visitor_type: string;
    level: number;
}

export interface VisitorLog extends NewVisitorLog {
    building_level: any;
    id: number;
    created_by: number;
    created_at: string;
    updated_at: string;
    deleted_by: number;
    deleted_at: string;
}

export function VisitorsLog(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const visitorLogFormRef = useRef(null);

    const [showVisitorsLog, setShowVisitorsLog] = useState(false);


    const [objects, setObjects] = useState<[VisitorLog, User | undefined, Guest | undefined, Office, Department | undefined][]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;


    const onSearch = (q?: any) => {
        Promise.all([
            Api.search('/visitors-logs', q || '', 1, {}, session.token),
            Api.search('/offices', '', 1, {}, session.token),
        ]).then(([data, offices]: { data: any[] }[]) => {
            data.data.forEach(([it]: any) => {
                it.office_name = offices.data.find((t: any) => t.id == it.office_id)?.name || it.office_id;
                it.user_name = offices.data.find((t: any) => t.id == it.user_id)?.name || it.user_id;
            });
            setObjects(data.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);

    const onDelete = (object: VisitorLog) => {
        setObjects(objects.filter(it => it[0].id != object.id));
        toast.success(t('Log deleted successfully'));
    }

    const onAdded = (object: { data: VisitorLog, user?: User, guest?: Guest, office: Office, department?: Department }) => {
        setObjects([[object.data, object.user, object.guest, object.office, object.department], ...objects]);
        setShowVisitorsLog(false);
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Visitors Log')}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => setShowVisitorsLog(true)} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('date')}
                            class="sort ${sort.dir.date}" style="width:10px" data-sort>
                            ${t('Date')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Name')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Identifier')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Badge Number')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Arrival Time')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Establishment')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Departure Time')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => VisistorLogRow(it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>
  <${Modal}
show=${showVisitorsLog}
onHide=${() => setShowVisitorsLog(false)}
backdrop="static"
keyboard=${false}
size="xl">
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Visitor Log')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
  <${AddForm} formRef=${visitorLogFormRef} onAdded=${onAdded}/>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShowVisitorsLog(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${() => (visitorLogFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>

`;
}


export function VisistorLogRow(data: [VisitorLog, User | undefined, Guest | undefined, Office, Department | undefined], onDelete: any) {

    const object: VisitorLog = data[0];
    const visitor = (data[1] || data[2]) as VisitorInfo;
    const office = data[3];
    const department: Department | undefined = data[4];


    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    const [departedAt, setDepartedAt] = useInput(object.departed_at);
    const [departedAtSaved, setDepartedAtSaved] = useState(false);

    const onSaveClick = (ev: any) => {
        if (!departedAt) {
            alert('Please select a departure time');
            return;
        }
        const newObject = { departed_at: departedAt + ':00' };
        Api.patch('/visitors-logs', object.id, newObject, state.token || '')
            .then(() => {
                console.log('UPDATED');
                object.departed_at = departedAt + ':00';
                setDepartedAtSaved(true);
                toast.success(t('Departure time updated successfully'));
            });
    };

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove('/visitors-logs/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    console.log('object', object);

    return html`<tr class="btn-reveal-trigger">
    <td class="name align-middle white-space-nowrap">
    <a href=${'/tracking-tables/visitors-log/' + object.id}>
            <h6>${object.date}</h6>
            </a>

    </td>
    <td class="phone align-middle white-space-nowrap desktop">${visitor.name}</td>
    <td class="phone align-middle white-space-nowrap desktop">
        <${IdentityTypeIcon} type=${visitor.identifier_type}/>
       ${visitor.identifier_type == 'I' ? visitor.identifier.slice(visitor.identifier.length - 3, visitor.identifier.length) + '...' : visitor.identifier}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.badge_number}</td>

    <td class="phone align-middle white-space-nowrap desktop">${object.arrived_at}</td>
    <td class="phone align-middle white-space-nowrap desktop">${office.name}</td>
    <td class="phone align-middle white-space-nowrap desktop d-flex" >
        ${object.departed_at || departedAtSaved ? departedAt : html`
        <input style="width:220px" type="time"  value=${departedAt} onInput=${setDepartedAt} class="form-control" />`}
        <!--<a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Save')}</span></a>-->
    </td>
    <!--<td class="phone align-middle white-space-nowrap desktop">${object.purpose?.slice(0, 50)}...</td>
    <td class="phone align-middle white-space-nowrap desktop">${department?.name}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.user_name}</td>-->
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
        <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    ${object.departed_at ? html`` : html`<a class="dropdown-item" onclick=${() => onSaveClick(object)}>Edit</a>`}
                    ${can(state, 'VisitorLog', 'delete') ? html`<a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a>` : ''}
                </div>
            </div>
        </div>
    </td>
</tr>`;
}

export function VisitorLogDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [object, setObject] = useState<{
        guest: any;
        visitor_user: any; data: VisitorLog, office: Office, department?: Department, user?: User
    } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/visitors-logs', id, session.token as any).then((data) => {
                setObject(data);
            });
        }
    }, []);


    const [showShare, setShowShare] = useState(false);
    const [shareUser, setShareUser] = useState<any>();
    const [shareValidated, setShareValidated] = useState(false);


    const onShareSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShareValidated(true);
        if (shareUser) {
            Api.post('/procedures/' + id + '/share', { user_id: shareUser.value }, session.token).then(() => {
                setShowShare(false);
                toast.success(t('Procedure shared successfully'));
            });
        }
    };

    const loadOptionsUsers = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, session.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });




    if (object) {

        const visitor: VisitorInfo = object.visitor_user || object.guest;

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${object.data.date}  ${visitor.name}</h5>
            </div>
            <div class="col-auto">
        </div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Date')}</p>
                    </div>
                    <div class="col">${object.data.date}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Name')}</p>
                    </div>
                    <div class="col">${visitor.name}</div>
                </div>
                <div class="row">
                <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Identifier')}</p>
                    </div>
                    <div class="col">
                    <${IdentityTypeIcon} type=${visitor.identifier_type}/>
                    ${visitor.identifier_type == 'I' ? visitor.identifier.slice(visitor.identifier.length - 3, visitor.identifier.length) + '...' : visitor.identifier}
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Badge Number')}</p>
                    </div>
                    <div class="col">${object.data.badge_number}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Building Level')}</p>
                    </div>
                    <div class="col">${object.data.building_level}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-0">${t('Arrival Time')}</p>
                    </div>
                    <div class="col">${object.data.arrived_at}</div>
                </div>
            </div>
            <div class="col-lg col-xxl-5 mt-4 mt-lg-0 offset-xxl-1">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Establishment')}</p>
                    </div>
                    <div class="col">${t(object.office.name)}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Department')}</p>
                    </div>
                    <div class="col">${object.department?.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Employee')}</p>
                    </div>
                    <div class="col">${object.user?.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Departure Time')}</p>
                    </div>
                    <div class="col">${object.data.departed_at}</div>
                </div>

            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}



export function IdentityTypeIcon({ type }: { type: string }) {
    return html`<img height="24px" style="margin-top:-7px;margin-left:7px" src=${{
        'I': require('/assets/img/id-card.png'),
        'P': require('/assets/img/passport.png'),
    }[type]} />`;
}


export interface Guest {
    name: string;
    name_ar: string;
    id: number;
    identifier: string;
    identifier_type: string;
    is_employee: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    deleted_by: number;
    created_by: number;
}


export function AddGuestModal({ show, setShow, onAdded }: { show: boolean, setShow: any, onAdded: any }) {

    const [form, setForm] = useState<Partial<Guest>>({});

    useEffect(() => {
        setForm({});
    }, [show]);

    const set = (key: keyof Guest, field?: string, index?: number) => (value: any) => {
        if (field && index !== undefined) {
            const item = (form[key] as any)[index] || {} as any;
            (form[key] as any)[index] = { ...item, [field]: value.target ? (value.target.type == 'checkbox' ? value.target.checked : value.target.value) : value };
            setForm({ ...form });

        } else {
            setForm({ ...form, [key]: value.target ? (value.target.type == 'checkbox' ? value.target.checked : value.target.value) : value });
        }
    }

    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const activity: any = undefined;

    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {



            if (activity?.id) {

                Api.patch('/guests', activity.id, form, state.token)
                    .then((activity) => {
                        toast.success(t('Guest updated successfully'));
                        route('/activities/' + activity.id);
                    });

            } else {
                form.is_employee = form.is_employee || false;
                set('is_employee')(form.is_employee);
                form.identifier_type = form.is_employee ? 'U' : 'I';
                Api.post('/guests', form, state.token).then((guest) => {

                    toast.success(t('Guest added successfully'));

                    if (onAdded) {
                        guest.guest = true;
                        onAdded(guest);
                    } else {
                        route('/guests/' + activity.id);
                    }
                });
            }

        }

    };



    return html`<${Modal}
show=${show}
onHide=${() => setShow(false)}
backdrop="static"
keyboard=${false}
>
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Guest')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
<form class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Name')}</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${set('name')}"
            value="${form.name}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Name')} (${t('Arabic')})</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${set('name_ar')}"
            value="${form.name_ar}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Identifier')}</label>
        <input required pattern=".{3,}" class="form-control" id="name" type="text" oninput="${set('identifier')}"
            value="${form.identifier}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="name">${t('Employee')}</label>
        <div class="form-control">
            <input class="form-check-input" id="name" type="checkbox" onchange="${(ev) => { console.log("EV", ev); set('is_employee')(ev) }}"  checked="${form.is_employee}" />
        </div>
    </div>

</form>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShow(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${onSubmit} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>`;
}