import { html } from "htm/preact";
import { generateId, getPage, Pagination, t, useInput } from "./core";
import { useContext, useEffect, useRef, useState } from "preact/hooks";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, debounce, filesStore, getDownloadUrl, getThumbnail, searchProducts, SessionContext, SFile, state, thumbnailsStore, url, useSession } from "./store";
import { Billable, Product } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ObjectId from "bson-objectid";
import * as storage from 'idb-keyval';
import CreatableSelect from 'react-select/creatable';
import { Folder, FolderModal } from "./procedures";



const options = citites.map(it => ({ label: it.c + ', ' + it.g, value: it.id1 + '-' + it.id2 }));
const insuranceOptions = [{ label: 'Selectioner' }, { label: 'CNSS', value: 'cnss' }, { label: 'CNRPS', value: 'cnrps' }];

/*
function useForm(pairs:[string,string][],callback:any){

    const debounce = 
    const out:any[] = [];
    for(const pair of pairs) {
        const [state, setState] = useState(pair[1]);
        out.push([state, (ev: any) => {
            setState(ev.target.value);


            // dispatch({collection, type: 'mutate', id,  data: {[pair[0]]: ev.target.value}})
        }]);
    }
    return out;
}*/

export function ProductForm({ product }: { product?: Product }) {

    const [name, setName] = useInput(product?.name || '');
    const [sku, setSku] = useInput(product?.sku || '');
    const [description, setDescription] = useInput(product?.description || '');
    const [price, setPrice] = useState(product?.price || 0);

    const [validated, setValidated] = useState(false);
    /*const [city, setCity] = useState(product?.city || {} as any);
    const [insurance, setInsurance] = useState(product?.insurance);
    const [insuranceId,setInsuranceId] = useInput(product?.insuranceId);
    const [phones,setPhones] = useState(product?.phones || []);
*/
    const [state, dispatch] = useSession();



    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            const id = product?._id || generateId(state.user?.organizationId || '');

            const data: Partial<Product> = { name, sku, description, price };

            dispatch({
                type: 'mutate', collection: 'products', id,
                data,
            });

            toast.success(product?._id ? 'Product mise à jour' : 'Product ajouté');
            route('/products/' + id);
            /* DB.put('Product',
                 randomString(9),
                 {
                     firstName,
                     lastName,
                     birthdate,
                     gender,
                     city,
                 }); */
        }

    };


    function handleCreate(value: any) {
        dispatch({
            type: 'mutate',
            collection: 'options',
            id: generateId(state.user?.organizationId || ''),
            data: { type: 'insurance', value },
        });
    };

    return html`<form onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-6">
        <label class="form-label" for="first-name">Titre</label>
        <input required pattern=".{3,}" class="form-control" id="first-name" type="text" oninput="${setName}"
            value=${name} />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="last-name">Sku</label>
        <input required pattern=".{3,}" class="form-control" id="last-name" type="text" oninput="${setSku}"
            value="${sku}" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="email1">Description</label>
        <textarea class="form-control" value="${description}" oninput="${setDescription}" required ></textarea>
        <div class="invalid-feedback">At least 8 characters</div>
    </div>
    <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" type="submit"> ${product?._id ? 'Update' : 'Créer'}</button>
    </div>
</form>`;
}


export function AddProduct() {
    return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Ajouter Produit</h5>
            </div>
            <div class="card-body bg-light">
                <${ProductForm} />
            </div>
        </div>
    </div>
</div>`;
}

export function UpdateProduct({ id }: { id: string }) {
    const [session] = useSession();
    const product = session.products.find(it => it._id == id);
    if (product) {
        console.log('PATIENT111', id, session.products.find(it => it._id == id))
        return html`<div class="row g-0">
    <div>
        <div class="card mb-3">
            <div class="card-header">
                <h5 class="mb-0">Update Product</h5>
            </div>
            <div class="card-body bg-light">
                <${ProductForm} product=${product} />
            </div>
        </div>
    </div>
</div>`;
    } else {
        return html`<div>Loading</div>`;
    }
}


export function ProductsOverview(props: any) {

    const [session] = useSession();

    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();


    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;
    const allProducts = searchProducts(session.products, q, sort, dir, page);
    const products = allProducts.slice((page - 1) * 20, page * 20);

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Products</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a href="/products-add" class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Ajouter</span></a>
                            <!--
                    <a class="btn btn-falcon-default btn-sm mx-2" type="button"><span class="fas fa-filter"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Filter</span></a>
                    <a class="btn btn-falcon-default btn-sm" type="button"><span class="fas fa-external-link-alt"
                            data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">Export</span></a>
-->
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                    <!--
                        <th>
                            <div class="form-check fs-0 mb-0 d-flex align-items-center">
                                <input class="form-check-input" id="checkbox-bulk-customers-select" type="checkbox"
                                    data-bulk-select='{"body":"table-customers-body","actions":"table-customers-actions","replacedElement":"table-customers-replace-element"}' />
                            </div>
                        </th>-->
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('no')}
                            class="sort ${sort.dir.no}" style="width:10px" data-sort>
                            N°</th>
                        <th onclick=${onSort('firstName')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.firstName}" data-sort>
                            Nom</th>
                      <!--  <th onclick=${onSort('email')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.email}" data-sort>Email
                        </th> -->
                        <th class="sort align-middle white-space-nowrap" >Téléphone</th>
                        <th class="sort pr-1 align-middle white-space-nowrap pl-5" data-sort="address"
                            style="min-width: 200px;">Addresse</th>
                        <th onclick=${onSort('birthdate')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.birthdate}" data-sort="joined">
                            Date de naissance</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${products.map(ProductRow)}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${allProducts.length} page=${page}/>
</div>`;
}


export function ProductRow(product: Product) {

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [])

    const [state, dispatch] = useSession();


    function onDeleteClick(product: Product) {
        dispatch({
            type: 'mutate',
            collection: 'products',
            id: product._id,
            data: { deletedAt: Date.now() },
        });
    };

    return html`<tr class="btn-reveal-trigger">
    <!--
    <td class="align-middle py-2" style="width: 28px;">
        <div class="form-check fs-0 mb-0 d-flex align-items-center">
            <input class="form-check-input" type="checkbox" id="customer-0"
                data-bulk-select-row="data-bulk-select-row" />
        </div>
    </td>
    -->
    <td class="no align-middle white-space-nowrap">${product}</td>
    <td class="name align-middle white-space-nowrap"><a href="/products/${product._id}">
                    <h6>${product.sku}</h6>
        </a></td>
    <td class="phone align-middle white-space-nowrap">${product.name}</td>
    <td class="address align-middle white-space-nowrap pl-5"></td>
    <td class="joined align-middle">${product.price}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
            <button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <!--<a class="dropdown-item" href="#!">Edit</a>-->
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(product)}>Delete</a></div>
            </div>
        </div>
    </td>
</tr>`;
}

function NoValue(label: string) {
    return html`<p class="font-italic text-400 mb-0">${label}</p>`;
}

function Value({ label, value }: { label: string, value: string }) {
    return value ? (html`${value}`) : NoValue(label);
}

export function ProductDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();
    const product = session.products.find(it => it._id == id);

    /*
    const saveNotes:any = useRef(debounce(function(notes:string){
        dispatch({type:'mutate',collection: 'products',id, data:{notes} });
    },4000)).current;
*/
    const setNotes = (ev: any) => dispatch({ type: 'mutate', collection: 'products', id, data: { notes: ev.target.value }, debounce: true });
    if (product) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${product._id}</h5>
            </div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/products/${id}/prescribe">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Prescrire un traitement</a></div>

            <div class="col-auto"><a class="btn btn-falcon-default btn-sm" href="/products/${id}/edit">
                    <${FontAwesomeIcon} icon=${['fas', 'pencil-alt']} /> Mettre à jour les détails</a></div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Titre</p>
                    </div>
                    <div class="col">${product.name}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Sku</p>
                    </div>
                    <div class="col">${product.sku}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Description</p>
                    </div>
                    <div class="col">${product.description}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">Price</p>
                    </div>
                    <div class="col">${product.price}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<${Drive} objectId=${product._id} idName="productId"></${Drive}>


`;

    } else {
        return html`<div>Product ${id} not found</div>`;
    }
}




export function Photos(product: Product) {


    const [photos, setPhotos] = useState((product.images || []).map(url => ({ url })));


    const onAddUrlClick = () => {
        let url = prompt("Add Image Url", "");
        if (url) {
            setPhotos([...photos, { url }]);
        }
    };

    const remove = photo => setPhotos([...photos.filter(it => it != photo)]);


    const move = (photo, dir) => {
        const index = photos.indexOf(photo);
        photos.splice(index, 1);
        let newIndex = index + dir;
        if (newIndex < 0) {
            newIndex = photos.length;
        } else if (newIndex > photos.length) {
            newIndex = 0;
        }
        photos.splice(newIndex, 0, photo);
        setPhotos([...photos]);
    }

    const onChange = ev => {
        console.log(ev);
        const file: File = ev.target.files[0];
        const objectUrl = URL.createObjectURL(file);
        setPhotos([...photos, { objectUrl } as any])
        fetch('/upload/image', {
            body: file,
            method: 'POST',
            headers: {
                'x-filename': file.name,
                'x-filetype': file.type,
            }
        })
            .then(res => res.text())
            .then(res => setPhotos([...photos.filter((it: any) => it.objectUrl != objectUrl), { url: JSON.parse(res).name }]));
    }

    function getUrl(photo) {
        return photo.url ? (photo.url.startsWith('http') ? photo.url : `/images/${photo.url}`) : photo.objectUrl;
    }

    return html`
    <style>
        i {

            height: 30px;
            width:30px;
            text-align: center;
            padding: 7px;
            transition: all 300ms;
        }
        i:hover {
            background-color: white;
            border-radius: 30px;
            box-shadow: 1px 1px 4px lightgrey;
            color:black;
        }
        .controls {
            position:relative;
            bottom:30px;
            height:0px;
            color:darkgray;
        }
        .image {
            background-size:contain;
            background-repeat: no-repeat;
            background-position: center;
            height:180px;
            transition: all 100ms;
        }
    </style>
    <fieldset>
        <legend>Photos</legend>
        <label>Upload</label>
        <input type="file" onChange=${onChange} name="image" placeholder=""/>
        <button onClick=${onAddUrlClick} type="button">Add from url</button>
        <div style="display:flex;flex-wrap:wrap;text-align:right;align-items:stretch">
        ${photos.map(photo => {
        return html`<div  draggable="true" style="width:25%;padding:5px;">
            <div class="image" style="background-image:url('${getUrl(photo)}');">
            <div>${(photo as any).objectUrl ? "UPLOADING" : ""} </div>
        </div>
                <div class="controls">
                <i onClick=${() => move(photo, -1)} class="fas fa-arrow-left"></i>
                <i onClick=${() => move(photo, 1)} class="fas fa-arrow-right"></i>
                <i onClick=${() => remove(photo)} class="fas fa-times"></i>
                </div>
            </div>`;
    })}
        </div>
        ${photos.map((photo, i) => html`<input type="hidden" name="images[${i}]" value="${photo.url}"/>`)}
    </fieldset>`;
}


let ALLTHUMBS = {};

export function Drive({ procedureId, readonly }: { procedureId?: number, readonly?: boolean }) {

    if (procedureId == 'undefined' as any) {
        procedureId = undefined;
    }

    const [state, dispatch] = useSession();
    const [files, doSetFiles] = useState([] as SFile[]);
    const [showFolder, setShowFolder] = useState(false);

    const [folders, setFolders] = useState([] as Folder[]);

    const [thumbs, setThumbsPure] = useState(ALLTHUMBS);


    const [filderToEdit, setFilderToEdit] = useState({} as Folder);


    const onEdit = (folder: Folder) => {
        setShowFolder(true);
        setFilderToEdit(folder);
    };


    const setThumbs = val => {
        setThumbsPure(val);
        ALLTHUMBS = { ...ALLTHUMBS, val };
    };
    let driveFiles: SFile[] = state.files;


    const onFolderAdded = (folder: Folder) => {
        folder.is_folder = true;
        setFolders([folder, ...folders]);
    }

    function setFiles(toUploadFiles: File[]) {
        for (const file of toUploadFiles) {
            const reader = new FileReader();
            reader.onload = (ev) => {

                const id = Math.random() * 1000000;

                doSetFiles([{ id, name: file.name + ' (Upload en cours)', created_at: Date.now() } as any, ...files]);

                const formData = new FormData();

                let filename = prompt(t('Filename'), file.name);

                if (!filename) {
                    return;
                }

                formData.append('file', new Blob([ev.target?.result || ''], { type: file.type }), filename);
                const options = {
                    method: 'POST',
                    body: formData,
                    // Uncomment to make it fail
                    headers: { "Authorization": 'Bearer ' + state.token },
                };
                // alert('upload ' + file._id);
                const folderId = new URLSearchParams(window.location.search).get('folder');

                fetch(url + '/files' + (procedureId ? '?procedure_id=' + procedureId : (folderId ? '?folder_id=' + folderId : '')), options)
                    .then(res => res.json())
                    .then(res => {
                        doSetFiles([res, ...files.filter(it => it.id != id)]);
                    });

            };
            reader.readAsArrayBuffer(file);
        }
    }

    function onFileClick(ev: MouseEvent, file: SFile) {
        ev.stopPropagation();
        ev.stopImmediatePropagation();
        ev.preventDefault();
        if (file.uploaded) {
            getDownloadUrl(state.token || '', file._id, file.name)
                .then(href => {
                    const link = document.createElement("a");
                    link.href = href;
                    link.download = file.name;
                    link.click();
                });
        } else {
            storage.get(file._id, filesStore)
                .then(fileData => {
                    const blob = new Blob([fileData.data], { type: file.type });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                });
        }
    }

    useEffect(() => {
        const toAdd = driveFiles.filter(it => !thumbs[it._id]);
        if (toAdd.length) {
            let all;
            storage.getMany(toAdd.map(it => it._id), filesStore)
                .then(fileDatas => {
                    all = fileDatas.reduce((p, n, i) => {
                        if (n) {
                            p[toAdd[i]._id] = window.URL.createObjectURL(new Blob([n.data], { type: n.type }));
                        }
                        return p;
                    }, thumbs);
                    setThumbs(all);
                    const ids = toAdd.map(it => it._id);
                    return Promise.all(ids.map(id => storage.get(id + '240', thumbnailsStore))).then(res => {
                        return res.map((it, i) => ({ _id: ids[i], blob: it })).filter(it => it.blob);
                    })
                })
                .then(thumbsData => {
                    all = thumbsData.reduce((p, n, i) => {
                        p[n._id] = window.URL.createObjectURL(n.blob);
                        return p;
                    }, all);
                    setThumbs({ ...all });
                    toAdd.filter(it => !all[it._id]).forEach(missing => {
                        Api.getBinary('/storage/' + missing._id + '/thumbnail?w=240', state.token || '')
                            .then(res => {
                                storage.set(missing._id + '240', res, thumbnailsStore).then(console.log);
                                all[missing._id] = window.URL.createObjectURL(res);
                                setThumbs({ ...all });
                            })
                    });
                });
        }
    }, [driveFiles.length]);



    const onSearch = (q?: any) => {

        const folderId = new URLSearchParams(window.location.search).get('folder');

        Api.search('/files', q || '', 1, procedureId ? { procedure_id: procedureId } : (folderId ? { folder_id: folderId } : {}), state.token || '')
            .then(res => {
                doSetFiles(res.data);
                res.folders = res.folders || [];
                res.folders.forEach(folder => folder.is_folder = true);
                setFolders(res.folders);
            });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, [window.location.href]);

    const onDelete = (file: SFile) => {
        doSetFiles(files.filter(it => it.id != file.id));
    }

    if (readonly && !files.length) {
        return html``;
    }

    const onAddFolderClick = () => {
        setFilderToEdit(undefined as any);
        setShowFolder(true);
    };

    return html`<div class="card mb-3">
                <div class="card-header d-flex flex-between-center bg-light py-2">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Files')}</h5>
                 ${readonly ? html`` : html`<div class="d-flex" style="gap:20px">
                            <div class="form-file">
        <a onClick=${onAddFolderClick} class="btn btn-falcon-default btn-sm" type="button"><span
                            class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')} ${t('Folder')}</span></a>
  </div>
                    
                    <div class="form-file">
        <input onchange=${(ev: any) => setFiles(ev.target.files)}  class="form-file-input" id="customFile" type="file" />
        <label class="form-file-label" for="customFile">
            <span class="form-file-text">${t('Choose file')}...</span>
            <span class="form-file-button">${t('Browse')}</span>
        </label>
        </div></div>
`}
                </div>
                <div class="card-body pb-0">
                ${folders.map(file => FileRow(file, onDelete, readonly || false, onEdit))}
                    ${files.map(file => FileRow(file, onDelete, readonly || false))}
                </div>
            </div>
            <${FolderModal} folder=${filderToEdit} show=${showFolder} setShow=${setShowFolder} onAdded=${onFolderAdded} />
            `;
}

export const Icons = {
    'pdf': require('./assets/img/file-pdf.png'),
    'img': require('./assets/img/file-img.png'),
    'padlock': require('./assets/img/padlock.png'),
};


const getFileType = (file: any) => {
    if (file.mime?.startsWith('image')) {
        return 'img';
    }
    if (file.mime?.endsWith('/pdf')) {
        return 'pdf';
    }
    if (file.name.endsWith('.jpg') || file.name.endsWith('.png') || file.name.endsWith('.jpeg')) {
        return 'img';
    }
    if (file.name.endsWith('.pdf')) {
        return 'pdf';
    }
    return '';
}


function FileRow(file: SFile, onDelete: any, readonly: boolean, onEdit?: any) {


    const [password, setPassword] = useState(file.password || '');

    const [state, dispatch] = useSession();

    const [open, setOpen] = useState(false);
    const [busy, setBusy] = useState(false);

    function onDeleteClick(file) {
        if (busy) return;
        setBusy(true);
        Api.remove('/files/' + file.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(file);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    function onProtectClick(file) {
        let newPassword = prompt('Please enter the password');
        if (newPassword) {
            Api.patch('/files', file.id + '?password', { password: newPassword }, state.token || '')
                .then(() => {
                    setOpen(false);
                    setPassword('LOL');
                    toast.success('Password added');
                })
                .catch((err) => {
                    console.log('ERROR', err);
                    //setBusy(false);
                });

        }
    }

    function onRemoveProtectClick(file) {
        let password = prompt(t('Enter password'));
        Api.patch('/files', file.id + `?password=${password}`, { password: '' }, state.token || '')
            .then(() => {
                setOpen(false);
                setPassword('');
                toast.success('Password removed');
            })
            .catch((err) => {
                console.log('ERROR', err);
                //setBusy(false);
            });
    }


    const onFileClick = (file: SFile) => {
        if (file.is_folder) {

        }
    };

    return html`<div class="d-flex mb-3 hover-actions-trigger align-items-center">
    <div class="file-thumbnail">
        <img loading="lazy" class="border h-100 w-100 fit-cover rounded-lg" src=${Icons[getFileType(file)]} alt="" />
        ${password ? html`<img loading="lazy" class="h-75 w-75 fit-cover" style="margin-top:-40px;" src=${Icons.padlock} alt="" />` : ''}
    </div>
    <div class="ml-3 flex-shrink-1 flex-grow-1">
      <h6 class="mb-1">
      <a href="/files${file.is_folder ? `?folder=${file.id}` : `/${file.id}`}" onClick=${() => onFileClick(file)} class="stretched-link text-900 font-weight-semi-bold">#${file.id} - ${file.name}</a></h6>
      <div class="fs--1"><span class="font-weight-semi-bold"></span><span class="font-weight-medium text-600 ml-2">${new Date(file.created_at).toISOString().replace('T', ' ')}</span></div>

      <div class="right-0 top-50 translate-middle-y" style="position:absolute;z-index:200">
      <div class="dropdown font-sans-serif">
          ${readonly ? html`` : html`<button onclick=${(ev: any) => {
            ev.preventDefault();
            ev.stopImmediatePropagation();
            setOpen(!open);
        }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
              type="button"
              id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
              aria-expanded="false">
              <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
          <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
              aria-labelledby="customer-dropdown-0">
              <div class="bg-white">
                ${file.is_folder ? html`<a class="dropdown-item text-danger" onclick=${() => onEdit(file)}>${t('Edit')}</a>` :
                password ? html`<a class="dropdown-item text-danger" onclick=${() => onRemoveProtectClick(file)}>${t('Unprotect')}</a>`
                    : html`<a class="dropdown-item text-danger" onclick=${() => onProtectClick(file)}>${t('Protect')}</a>`}
                  <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(file)}>${t('Delete')}</a></div>
          </div>`}
      </div>
  </div>

    </div>
  </div>
  <hr class="bg-200" />`;
}