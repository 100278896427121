import { html } from "htm/preact";
import { useEffect, useRef, useState } from "preact/hooks";
import { generateId, getPage, Pagination, t, useInput } from "./core";

import Select from 'react-select'
import { citites, socialSecurityOptions } from "./data";

import { Api, can, debounce, filesStore, getDownloadUrl, merge, searchObjects, SessionContext, SFile, state, useSession } from "./store";
import { Billable } from "./shared/core";
import { route } from "preact-router";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CreatableSelect from 'react-select/creatable';
import { Drive } from "./products";
import { FileUpload, ObjectRow, UpdateObject } from "./crud";
import AsyncCreatableSelect from "react-select/async-creatable";
import { Button, Modal } from "react-bootstrap";
import { ActivityForm } from "./activites";
import { Contact } from "./contacts";
import { IdentifierTypes } from "./entities";
import AsyncSelect from "react-select/async";
import { Department } from "./establishments-types";
import { Office, User } from "./users";
import { AddGuestModal, Guest, IdentityTypeIcon } from "./visitors-log";


export interface MaintenanceLogResponse {
    data: MaintenanceLog;
    users: User[];
    guests: Guest[];
}

export function AddForm({ data, formRef, onAdded }: { data?: MaintenanceLog, formRef: any, onAdded: (res: MaintenanceLogResponse) => void }) {


    const [officesOptions, setOfficesOptions] = useState<any[]>([]);
    const [form, setForm] = useState<Partial<MaintenanceLog>>({
        kind: { label: t('maintenance'), value: 'maintenance' },
        date: new Date().toISOString().split('T')[0],
    });

    const set = (key: keyof MaintenanceLog, field?: string, index?: number) => (value: any) => {
        if (field && index !== undefined) {
            const item = (form[key] as any)[index] || {} as any;
            (form[key] as any)[index] = { ...item, [field]: value.target ? value.target.value : value };
            setForm({ ...form });

        } else {
            setForm({ ...form, [key]: value.target ? value.target.value : value });
        }
    }

    const [technicians, setTechnicians] = useState<any[]>(data?.technicians ?? []);


    const [validated, setValidated] = useState(false);
    const [state, dispatch] = useSession();

    const [showAddGuest, setShowAddGuest] = useState(false);

    const [onGuestAddedIndex, setOnGuestAddedIndex] = useState(0);

    const onGuestAdded = (guest: any) => {
        guest.guest = true;
        setTechnicians([...technicians.map((it, i) => i == onGuestAddedIndex ? guest : it)]);
        setShowAddGuest(false);
    };

    useEffect(() => {
        Api.search('/offices', '', 1, {}, state.token).then((res) => {
            setOfficesOptions(res.data.map((it: any) => ({ label: it.name, value: it.id })));
        });
    }, []);


    const onSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setValidated(true);
        if ((ev.target as any).checkValidity()) {

            if (!form.kind) {
                return;
            }


            const newObject = { ...form };

            Object.keys(newObject).forEach((key) => {
                if (newObject[key]?.value) {
                    newObject[key] = newObject[key].value;
                }
            });

            newObject.users_ids = technicians.filter(it => !it.guest).map(it => it.id);
            newObject.guests_ids = technicians.filter(it => it.guest).map(it => it.id);

            if (data?.id) {

                Api.patch('/maintenance-logs', data.id, newObject, state.token)
                    .then((entity) => {
                        toast.success(t('Log updated successfully'));
                        //route('/entities/' + entity.id);
                    });

            } else {
                Api.post('/maintenance-logs', newObject, state.token).then((entity: MaintenanceLogResponse) => {
                    toast.success(t('Log added successfully'));
                    if (onAdded) {
                        onAdded(entity);
                    } else {
                        // route('/entities/' + entity.id);
                    }
                });
            }

        }

    };


    const loadUsersOptions = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadDepartmentsOptions = (inputValue: string) =>
        Api.search('/departments', inputValue, 1, {}, state.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });

    const loadVisitorsOptions = (inputValue: string) =>
        Promise.all([
            Api.search('/users', inputValue, 1, {}, state.token),
            Api.search('/guests', inputValue, 1, {}, state.token)
        ]).then(([users, guests]) => {
            guests.data.forEach(it => it.guest = true);
            return users.data.concat(guests.data);
        });
    const handleCreateGuest = (index) => ((inputValue: string) => {
        setShowAddGuest(true);
        setOnGuestAddedIndex(index);
    });

    return html`<form ref=${formRef} id="entity-form" onsubmit="${onSubmit}" class="row g-3 needs-validation ${validated ? 'was-validated' : ''}" novalidate>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Intervention Type')}</label>
        <${Select} value=${form.kind}
        options=${[{ label: t('maintenance'), value: 'maintenance' }, { label: t('repair'), value: 'repair' }]} onChange=${set('kind')}
        className="rs form-control ${validated && (form.kind ? 'is-valid' : 'is-invalid')}" />
    </div>
    <div class="col-lg-3">
        <label class="form-label" for="type">${t('Intervention Date')}</label>
        <input type="date"  value=${form.date} onInput=${set('date')} class="form-control" />
    </div>
    <div class="col-lg-6">
        <label class="form-label" for="type">${t('Intervention Authorization')}</label>
        <input type="text"  value=${form.authorization} onInput=${set('authorization')} class="form-control" />
    </div>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Equipment Name')}</label>
        <input type="text" class="form-control" id="name" value=${form.equipment} onInput=${set('equipment')} required />
        <div class="invalid-feedback">${t('Name is required')}</div>
    </div>
    <div class="col-lg-12">
        <label class="form-label" for="name">${t('Nature and description of the intervention')}</label>    
        <textarea value=${form.notes} class="form-control" rows="2" onInput=${set('notes')} ></textarea>
        <div class="invalid-feedback">${t('Notes')} ${t('is required')}</div>
    </div>




            <label class="form-label" for="name">${t('Technicians')}</label>
    <div class="col-lg-12">
    ${technicians?.map((visitor, index) => html`
    <div class="col-lg-12">
        <div class="row">
                <div class="col-lg-6">
                <label class="form-label" for="name">${t('Name')}</label>
<${AsyncCreatableSelect} 
    getOptionLabel=${option => option.__isNew__ ? t('Add') + ` '${option.value}'` : option.name + ` (${t(option.guest ? 'Guest' : 'Employee')})`} getOptionValue=${option => option.id} cacheOptions defaultOptions
isClearable onCreateOption=${handleCreateGuest(index)} loadOptions=${loadVisitorsOptions}  value=${visitor} onChange="${(ev) => setTechnicians([...technicians.map((tech, i) => i == index ? ev : tech)])}"
    className="rs form-control ${validated && (visitor ? 'is-valid' : 'is-invalid')}" />
</div>
    ${visitor ? html`<div class="col-lg-4">
                <label class="form-label" for="name">${t('Identifier')}</label>

        <div class="form-control">
        <${IdentityTypeIcon} type=${visitor.identifier_type}/>
        ${visitor.identifier}
        </div>
    </div>` : html`<div class="col-lg-4"></div>`}

<div class="col-lg-2" style="padding-top:30px">
                        <button onClick=${() => setTechnicians([...technicians.filter((it, i) => i !== index)])} class="btn btn-primary" type="button"> ${t('Remove')}</button>
</div>
</div>
    </div>
    `)}
    <button style="width:30%;margin-top:20px"  onClick=${() => setTechnicians([...technicians, undefined])} class="btn btn-primary" type="button"> ${t('Add')} ${t('Technicians')}</button>
</div>

    ${formRef ? '' : html`
    <div class="col-12 d-flex justify-content-end">
        <button id="entity-submit" class="btn btn-primary" type="submit"> ${data?.id ? t('Update') : t('Create')}</button>
    </div>`}
</form>
<${AddGuestModal} show=${showAddGuest} setShow=${setShowAddGuest} onAdded=${onGuestAdded} />
`;
}
/*
    pub kind: String,
    pub date: NaiveDate,
    pub authorization: String,
    pub notes: String,
    pub equipment: String,
    pub technicians: Vec<Technician>,
    pub created_by: i64,
*/

export interface Technician {
    name: string;
    identifier: string;
}

export interface NewMaintenanceLog {
    kind: string;
    date: string;
    authorization: string;
    notes: string;
    equipment: string;
    technicians: Technician[];
}

export interface MaintenanceLog extends NewMaintenanceLog {
    id: number;
    created_by: number;
    created_at: string;
    updated_at: string;
    deleted_by: number;
    deleted_at: string;
}

export function MaintenanceLog(name: string, path: string, ObjectRow_: any, props: any) {

    const [session, dispatch] = useSession();

    const visitorLogFormRef = useRef(null);

    const [showMaintenanceLog, setShowMaintenanceLog] = useState(false);


    const [objects, setObjects] = useState<MaintenanceLog[]>([]);
    const [sort, setSort] = useState({ field: '', dir: {} as any });

    const onSort = (field: string) => {
        return () => {
            setSort({ field, dir: { [field]: sort.dir[field] == 'asc' ? 'desc' : 'asc' } });
        };
    };

    const page = getPage();

    const q = session.search.trim().toLowerCase();
    const dir = sort.dir[sort.field] == 'asc' ? 1 : -1;


    const onSearch = (q?: any) => {
        Promise.all([
            Api.search('/maintenance-logs', q || '', 1, {}, session.token),
            Api.search('/offices', '', 1, {}, session.token),
        ]).then(([data, offices]: {
            users: any;
            guests: any; data: any[]
        }[]) => {
            data.data.forEach((it: any) => {
                it.users = it.users_ids.map((id: any) => data.users.find((t: any) => t.id == id));
                it.guests = it.guests_ids.map((id: any) => data.guests.find((t: any) => t.id == id));
            });
            setObjects(data.data);
        });
    };

    useEffect(() => {
        onSearch();
        dispatch({ type: 'onSearch', data: onSearch });
    }, []);

    const onDelete = (object: MaintenanceLog) => {
        setObjects(objects.filter(it => it.id != object.id));
        toast.success(t('Log deleted successfully'));
    }

    const onAdded = (res: MaintenanceLogResponse) => {
        res.data.users = res.data.users_ids.map((id: any) => res.users.find((t) => t.id == id));
        res.data.guests = res.data.guests_ids.map((id: any) => res.guests.find((t) => t.id == id));
        setObjects([res.data, ...objects]);
        setShowMaintenanceLog(false);
    }

    return html`<div class="card mb-3" id="customersTable"
    data-list='{"valueNames":["name","email","phone","address","joined"],"page":10,"pagination":true}'>
    <div class="card-header">
        <div class="row flex-between-center">
            <div class="col-4 col-sm-auto d-flex align-items-center pr-0">
                <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">${t('Maintenance Log')}</h5>
            </div>
            <div class="col-8 col-sm-auto text-right pl-2">
                <div id="table-customers-replace-element">
                    <a onClick=${() => setShowMaintenanceLog(true)} class="btn btn-falcon-default btn-sm" type="button">
                    <span class="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span
                            class="d-none d-sm-inline-block ml-1">${t('Add')}</span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0" style="height:${window.innerHeight - 240}px;overflow:auto">
        <div class="table-responsive">
            <table class="table table-sm table-striped fs--1 mb-0">
                <thead class="bg-200 text-900">
                    <tr>
                        <th class="no align-middle white-space-nowrap py-2" onclick=${onSort('date')}
                            class="sort ${sort.dir.date}" style="width:10px" data-sort>
                            ${t('Date')}</th>
                        <th onclick=${onSort('name')}
                            class="sort pr-1 align-middle white-space-nowrap ${sort.dir.name}" data-sort>${t('Intervention Type')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Intervention Authorization')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Nature and description of the intervention')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Equipment Name')}</th>
                        <th class="sort align-middle white-space-nowrap desktop" >${t('Technicians')}</th>
                        <th class="align-middle no-sort"></th>
                    </tr>
                </thead>
                <tbody class="list" id="table-customers-body">
                    ${objects.map(it => VisistorLogRow(it, onDelete))}
                    <br/>
        <br/>
                </tbody>
            </table>
        </div>
    </div>
    <${Pagination} count=${objects.length} page=${page}/>
</div>
  <${Modal}
show=${showMaintenanceLog}
onHide=${() => setShowMaintenanceLog(false)}
backdrop="static"
keyboard=${false}
size="xl">
<${Modal.Header} closeButton>
  <${Modal.Title}>${t('Add')} ${t('Maintenance Log')}</Modal.Title>
</${Modal.Header}>
<${Modal.Body}>
  <${AddForm} formRef=${visitorLogFormRef} onAdded=${onAdded}/>
</${Modal.Body}>
<${Modal.Footer}>
  <${Button} variant="secondary" onClick=${() => setShowMaintenanceLog(false)}>
    Close
    </${Button}>
  <${Button} variant="primary" onClick=${() => (visitorLogFormRef.current as any).dispatchEvent(new Event('submit'))} >${t('Add')}</${Button}>
</${Modal.Footer}>
</${Modal}>

`;
}


export function VisistorLogRow(object: MaintenanceLog, onDelete: any) {

    let data = {};
    const [open, setOpen] = useState(false);
    const [state, dispatch] = useSession();
    const [busy, setBusy] = useState(false);

    const [departedAtSaved, setDepartedAtSaved] = useState(false);

    useEffect(() => {
        const handler = () => setOpen(false);
        document.addEventListener('click', handler, { passive: true });
        return () => {
            document.removeEventListener('click', handler);
        };
    }, []);


    function onDeleteClick(object: any) {
        if (busy) return;
        setBusy(true);
        Api.remove('/maintenance-logs/' + object.id, state.token || '')
            .then(() => {
                console.log('DELETED');
                setOpen(false);
                onDelete(object);
                setBusy(false);
            })
            .catch((err) => {
                console.log('ERROR', err);
                setBusy(false);
            });
    };

    console.log('object', object);

    return html`<tr class="btn-reveal-trigger">
    <td class="name align-middle white-space-nowrap">
    <a href=${'/tracking-tables/maintenance-log/' + object.id}>
            <h6>${object.date}</h6>
            </a>

    </td>
    <td class="phone align-middle white-space-nowrap desktop">${t(object.kind)}</td>
    <td class="phone align-middle white-space-nowrap desktop">
        ${object.authorization}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.notes}</td>

    <td class="phone align-middle white-space-nowrap desktop">${object.equipment}</td>
    <td class="phone align-middle white-space-nowrap desktop">${object.users.concat(object.guests).map(it => it.name).join(', ')}</td>
    <td class="align-middle white-space-nowrap text-right actions-column">
        <div class="dropdown font-sans-serif">
        ${can(state, 'MaintenanceLog', 'delete') ? html`
            <button onclick=${(ev: any) => {
                ev.preventDefault();
                ev.stopImmediatePropagation();
                setOpen(!open);
            }} class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                type="button"
                id="customer-dropdown-0" data-toggle="dropdown" data-boundary="window" aria-haspopup="true"
                aria-expanded="false">
                <${FontAwesomeIcon} icon=${['fas', 'ellipsis-h']} class="fs--1" /></button>
            <div class="dropdown-menu dropdown-menu-right border py-0 ${open ? 'show' : ''}"
                aria-labelledby="customer-dropdown-0">
                <div class="bg-white">
                    <a class="dropdown-item text-danger" onclick=${() => onDeleteClick(object)}>Delete</a></div>
            </div>`: ''}
        </div>
    </td>
</tr>`;
}

export function MaintenanceLogDetails({ id }: { id?: string }) {

    const [session, dispatch] = useSession();

    const [object, setObject] = useState<{ data: MaintenanceLog, users: User[], guests: Guest[] } | undefined>();

    useEffect(() => {
        if (id) {
            Api.get('/maintenance-logs', id, session.token as any).then((data) => {
                data.data.users = data.data.users_ids.map((id: any) => data.users.find((t: any) => t.id == id));
                data.data.guests = data.data.guests_ids.map((id: any) => data.guests.find((t: any) => t.id == id));
                setObject(data);
            });
        }
    }, []);


    const [showShare, setShowShare] = useState(false);
    const [shareUser, setShareUser] = useState<any>();
    const [shareValidated, setShareValidated] = useState(false);

    const onShareSubmit = async (ev: Event) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShareValidated(true);
        if (shareUser) {
            Api.post('/procedures/' + id + '/share', { user_id: shareUser.value }, session.token).then(() => {
                setShowShare(false);
                toast.success(t('Procedure shared successfully'));
            });
        }
    };

    const loadOptionsUsers = (inputValue: string) =>
        Api.search('/users', inputValue, 1, {}, session.token).then((res) => {
            return res.data.map((it: any) => ({ label: it.name, value: it.id }));
        });




    if (object) {

        return html`<div class="card mb-3">
    <div class="card-header">
        <div class="row align-items-center">
            <div class="col">
                <h5 class="mb-0">#${object.data.date}  ${t(object.data.kind)}</h5>
            </div>
            <div class="col-auto">
        </div>
        </div>
    </div>
    <div class="card-body bg-light border-top">
        <div class="row">
            <div class="col-lg col-xxl-5">
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Date')}</p>
                    </div>
                    <div class="col">${object.data.date}</div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Intervention Authorization')}</p>
                    </div>
                    <div class="col">${object.data.authorization}</div>
                </div>
                <div class="row">
                <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Nature and description of the intervention')}</p>
                    </div>
                    <div class="col">
                    ${object.data.notes}
                    </div>
                </div>
                <div class="row">
                    <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Equipment Name')}</p>
                    </div>
                    <div class="col">${object.data.equipment}</div>
                </div>
            </div>
             <div class="col-lg col-xxl-5">
                <div class="row">
                <div class="col-5 col-sm-4">
                        <p class="font-weight-semi-bold mb-1">${t('Technicians')}</p>
                    </div>
                    <div class="table-responsive">
                        <table class="table table-sm table-striped fs--1 mb-0">
                            <thead class="bg-200 text-900">
                                <tr>
                                    <th class="sort" data-sort="date">${t('Name')}</th>
                                    <th class="sort align-middle" data-sort="name">${t('Identifier')}</th>
                                </tr>
                            </thead>
                            <tbody class="list" id="table-customers-body">
            ${object.data.users.map((visitor, index) => html`
                                <tr class="btn-reveal-trigger">
                <td class="align-middle">${visitor.name}
                </td>
                <td class="align-middle py-2">${visitor.identifier} <${IdentityTypeIcon} type=${visitor.identifier_type}/>
                </td>
            </tr>`)}
            ${object.data.guests.map((visitor, index) => html`
                <tr class="btn-reveal-trigger">
                <td class="align-middle">${visitor.name}
                </td>
                <td class="align-middle py-2">${visitor.identifier} <${IdentityTypeIcon} type=${visitor.identifier_type}/>
                </td>

</tr>`)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
`;

    } else {
        return html`<div>User ${id} not found</div>`;
    }
}

