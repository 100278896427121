import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { html } from 'htm/preact';
import { useState } from "preact/hooks";
import { base58, to32 } from './shared/core';
import { SessionContext } from './store';


export function useInput(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState(ev.target.value);
        if (callback) callback(ev.target.value);
    }, setState];
}

/*
export function useForm(init: any, callback?: any) {
    const [state, setState] = useState(init);
    return [state, (ev: any) => {
        setState({...state, ...data);
        if (callback) callback(ev.target.value);
    }];
}*/


const longToByteArray = function (long: number) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0, 0, 0];

    for (var index = 0; index < byteArray.length; index++) {
        var byte = long & 0xff;
        byteArray[index] = byte;
        long = (long - byte) / 256;
    }

    return Uint8Array.from(byteArray.reverse());
};

export function generateId(organizationId: string, length = 16) {
    const t = longToByteArray(Math.round(Date.now() / 1000));
    const time = base58.encode(t);
    return organizationId + time + base58.random(length - (organizationId + time).length);
}

export function Pagination({ count, page, pageSize }: { count: number, page: number, pageSize?: number }) {
    pageSize = pageSize || 20;
    const pagesCount = Math.ceil(count / pageSize);
    return html`<div class="card-footer d-flex align-items-center justify-content-center">
    <a href="${window.location.pathname}?page=${page - 1}"
        class="btn btn-sm btn-falcon-default mr-1 ${page <= 1 ? 'disabled' : ''}" type="button" title="Previous"
        data-list-pagination="prev">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-left']} /></a>
    <ul class="pagination mb-0" style="width:150px;">
        <span style="margin:auto">
            ${page}/${pagesCount} (${count})
        </span>
    </ul>
    <a href="${window.location.pathname}?page=${page + 1}"
        class="btn btn-sm btn-falcon-default ml-1 ${page >= pagesCount ? 'disabled' : ''}" type="button" title="Next"
        data-list-pagination="next">
        <${FontAwesomeIcon} icon=${['fas', 'chevron-right']} />
    </a>
</div>`;
}

export function getPage() {
    return parseInt(new URLSearchParams(location.search).get('page') || '1');

}

const MAP = [
    ['active', 'نشط', 'Actif'],
    ['Users', 'المستخدمين', 'Utilisateurs'],
    ['Settings', 'الإعدادات', 'Paramètres'],
    ['Password', 'كلمة المرور', 'Mot de passe'],
    ['Loading', 'جار التحميل', 'Chargement'],
    ['Create', 'إنشاء', 'Créer'],
    ['Edit', 'تعديل', 'Modifier'],
    ['Delete', 'حذف', 'Supprimer'],
    ['Save', 'حفظ', 'Enregistrer'],
    ['Cancel', 'إلغاء', 'Annuler'],
    ['Search', 'بحث', 'Rechercher'],
    ['Yes', 'نعم', 'Oui'],
    ['No', 'لا', 'Non'],
    ['Add', 'إضافة', 'Ajouter'],
    ['Update', 'تحديث', 'Mettre à jour'],
    ['Status', 'الحالة', 'Statut'],
    ['Name', 'الاسم', 'Nom'],
    ['Name/Company Name', 'الاسم الجتماعي /الاسم و اللقب', 'Nom/Nom de l\'entreprise'],
    ['Roles', 'الصلاحيات', 'Rôles'],
    ['Unique Identifier', 'المعرف', 'Identifiant unique'],
    ['User added successfully', 'تمت إضافة المستخدم بنجاح', 'Utilisateur ajouté avec succès'],
    ['User updated successfully', 'تم تحديث المستخدم بنجاح', 'Utilisateur mis à jour avec succès'],
    ['Employee', 'موظف', 'Employé'],
    ['Administration', 'ادارة التطبيق', 'Administration'],
    ['Admin', 'مدير', 'Administrateur'],
    ['Files', 'الملفات', 'Fichiers'],
    ['Procedures', 'الإجراءات', 'Procédures'],
    ['Procedures Types', 'أنواع الإجراءات', 'Types de procédures'],
    ['Procedure Type', 'نوع الإجراء', 'Type de procédure'],
    ['Taxable Entity', 'المطالب بالضريبة', 'Entité taxable'],
    ['Contact Person', 'الممثل القانوني', 'Personne de contact'],
    ['Contact Persons', 'الممثل القانوني', 'Personnes de contact'],
    ['Taxable Entities', 'المطالببن بالضريبة', 'Entités taxables'],
    ['Tax Identification Number', 'المعرف الجبائي', 'Numéro d\'identification fiscale'],
    ['Legal Status', 'الوضع القانوني', 'Statut juridique'],
    ['Address', 'العنوان', 'Adresse'],
    ['Procedure', 'الإجراء', 'Procédure'],
    ['Contacts', 'الممثل القانوني', 'Contacts'],
    ['Contact', 'الممثل القانوني', 'Contact'],
    ['National Identification Number', 'الرقم الوطني', 'Numéro d\'identification nationale'],
    ['Phone', 'الهاتف', 'Téléphone'],
    ['Email', 'البريد الإلكتروني', 'E-mail'],
    ['Postal Code', 'الرمز البريدي', 'Code postal'],
    ['User', 'المستخدم', 'Utilisateur'],
    ['User removed successfully', 'تم حذف المستخدم بنجاح', 'Utilisateur supprimé avec succès'],
    ['Serial Number', 'الرقم التسلسلي', 'Numéro de série'],
    ['Ordering Number', 'رقم الطلب', 'Numéro de commande'],
    ['Accepted Date', 'تاريخ القبول', 'Date d\'acceptation'],
    ['Closed Date', 'تاريخ الإغلاق', 'Date de clôture'],
    ['Date', 'التاريخ', 'Date'],
    ['pending', 'قيد الانتظار', 'En attente'],
    ['Result', 'النتيجة', 'Résultat'],
    ['Share', 'مشاركة', 'Partager'],
    ['Shared by', 'مشاركة بواسطة', 'Partagé par'],
    ['to', 'إلى', 'À'],
    ['at', 'في', 'À'],
    ['Vat Code', 'رمز الضريبة', 'Code TVA'],
    ['Category Code', 'رمز الفئة', 'Code catégorie'],
    ['Main Activity', 'النشاط الرئيسي', 'Activité principale'],
    ['Common Name', 'الاسم الشائع', 'Nom commun'],
    ['Activity', 'النشاط', 'Activité'],
    ['Secondary Activity', 'النشاط الثانوي', 'Activité secondaire'],
    ['Code', 'الرمز', 'Code'],
    ['Owner', 'الموضف المسؤول عن فتح الاجراء', 'Responsable de l\'ouverture de la procédure'],
    ['Accept', 'قبول', 'Accepter'],
    ['Close', 'إغلاق', 'Fermer'],
    ['Are you sure you want to accept this procedure?', 'هل أنت متأكد أنك تريد قبول هذا الإجراء؟', 'Êtes-vous sûr de vouloir accepter cette procédure ?'],
    ['Please enter the status of the procedure on closing', 'الرجاء إدخال حالة الإجراء عند الإغلاق', 'Veuillez entrer l\'état de la procédure lors de la clôture'],
    ['Procedure closed successfully', 'تم إغلاق الإجراء بنجاح', 'Procédure clôturée avec succès'],
    ['Procedure accepted successfully', 'تم قبول الإجراء بنجاح', 'Procédure acceptée avec succès'],
    ['Regulatory Time Limit', 'الحد الزمني التنظيمي', 'Délai réglementaire'],
    ['days', 'أيام', 'Jours'],
    ['Office', 'االقسم', 'Unité'],
    ['Download', 'تحميل', 'Télécharger'],
    ['Access', 'الحقوق', 'Accès'],
    ['Preview', 'معاينة', 'Aperçu'],
    ['shared successfully', 'تمت المشاركة بنجاح', 'Partagé avec succès'],
    ['Procedure Name', 'اسم الإجراء', 'Nom de la procédure'],
    ['This element exists in the system', 'هذا العنصر موجود في النظام', 'Cet élément existe dans le système'],
    ['Contact Person Role', 'صفة الممثل القانوني', 'Rôle de la personne de contact'],
    ['open', 'مفتوح', 'Ouvert'],
    ['done', 'منجز', 'Terminé'],
    ['rejected', 'مرفوض', 'Rejeté'],
    ['accepted', 'مقبول', 'Accepté'],
    ['Occupation', 'المهنة', 'Occupation'],
    ["Shares", "المشاركات", "Partages"],
    ['Change Ownership', 'تغيير الملكية', 'Changer de propriétaire'],
    ['File', 'الملف', 'Fichier'],
    ['Change', 'تغيير', 'Changer'],
    ['ownership changed successfully', 'تم تغيير الملكية بنجاح', 'Propriété changée avec succès'],
    ['Remove', 'حذف', 'Supprimer'],
    ['Tracking tables', 'جداول المتابعة', 'Tableaux de suivi'],
    ['Inactive', 'غير نشط', 'Inactif'],
    ['Active', 'نشط', 'Actif'],
    ['inactive', 'غير نشط', 'Inactif'],
    ['Establishment', 'المؤسسة', 'Établissement'],
    ['Establishments', 'المؤسسات', 'Établissements'],
    ['Establishment Type', 'نوع المؤسسة', 'Type d\'établissement'],
    ['Establishments Types', 'أنواع المؤسسات', 'Types d\'établissements'],
    ['Department', 'القسم', 'Département'],
    ['Departments', 'الأقسام', 'Départements'],
    ['Arrival Time', 'وقت الوصول', 'Heure d\'arrivée'],
    ['Departure Time', 'وقت المغادرة', 'Heure de départ'],
    ['Badge Number', 'رقم الشارة', 'Numéro de badge'],
    ['Identifier', 'المعرف', 'Identifiant'],
    ['Purpose', 'الغرض', 'But'],
    ['Visitors Log', 'سجل الزوار', 'Journal des visiteurs'],
    ['Maintenance Log', 'سجل الصيانة', 'Journal de maintenance'],
    ['Equipments Log', 'سجل المعدات', 'Journal des équipements'],
    ['repair', 'إصلاح', 'Réparation'],
    ['maintenance', 'صيانة', 'Maintenance'],
    ['Intervention Type', 'نوع التدخل', 'Type d\'intervention'],
    ['Intervention Date', 'تاريخ التدخل', 'Date d\'intervention'],
    ['Intervention Authorization', 'تفويض التدخل', 'Autorisation d\'intervention'],
    ['Equipment Name', 'اسم المعدة', 'Nom de l\'équipement'],
    ['Nature and description of the intervention', 'طبيعة ووصف التدخل', 'Nature et description de l\'intervention'],
    ['Technicians', 'الفنيين', 'Techniciens'],
    ['Equipment Log', 'سجل المعدات', 'Journal des équipements'],
    ['Technician', 'الفني', 'Technicien'],
    ['Equipment', 'المعدة', 'Équipement'],
    ['Equipment Type', 'نوع المعدة', 'Type d\'équipement'],
    ['Equipment Reference', 'المرجع', 'Référence'],
    ['Equipment Model', 'الموديل', 'Modèle'],
    ['Person ID', 'رقم الشخص', 'Identifiant de la personne'],
    ['Destination', 'الوجهة', 'Destination'],
    ['Date Time', 'التاريخ و الوقت', 'Date Heure'],
    ['entry', 'دخول', 'Entrée'],
    ['exit', 'خروج', 'Sortie'],
    ['Visitor Log', 'سجل الزوار', 'Journal des visiteurs'],
    ['Building Level', 'مستوى المبنى', 'Niveau du bâtiment'],
    ['Visitor', 'الزائر', 'Visiteur'],
    ['Reference', 'المرجع', 'Référence'],
    ['Arabic', 'العربية', 'Arabe'],
    ['Tracking Table for the Closure of Tax Files', 'جدول متابعة غلق الملفات الجبائية', 'Tableau de suivi de la fermeture des dossiers fiscaux'],
    ['Request Date', 'تاريخ المطلب', 'Date de demande'],
    ['Acceptance Date', 'تاريخ القبول', 'Date d\'acceptation'],
    ['Name and surname or social name', 'الاسم و اللقب أو الاسم الجتماعي', 'Nom et prénom ou raison sociale'],
    ['Chosen Address for Correspondence', 'محل المخابرة المختار', 'Adresse choisie pour la correspondance'],
    ['Outcome of the Request', 'مآل المطلب', 'Résultat de la demande'],
    ['Tax Identification Number', 'معرف جبائي', 'Identifiant fiscal'],
    ['Closure Date', 'تاريخ الإغلاق', 'Date de clôture'],
    ['Service Duration', 'مدة الخدمة', 'Durée du service'],
    ['Agent in Charge of the File', 'العون المكلف بالملف', 'Agent en charge du dossier'],
    ['Ordinal Number', 'الرقم الرتبي', 'Numéro d\'ordre'],
    ['Tracking Table for Requests for Clarifications and Guidance', 'جدول متابعة طلبات التوضيحات والإرشادات', 'Tableau de suivi des demandes de clarifications et d\'orientations'],
    ['Clarifications and Guidance Request Subject', 'موضوع طلب التوضيحات والإرشادات', 'Sujet de la demande de clarifications et d\'orientations'],
    ['Notification Date', 'تاريخ االتبليغ', 'Date de notification'],
    ['Number', 'العدد', 'Nombre'],
    ['Agent', 'العون', 'Agent'],
    ['Notes', 'ملاحظات', 'Notes'],
    ['Print', 'طباعة', 'Imprimer'],
    ['Type', 'النوع', 'Type'],
    ['Browse', 'تصفح', 'Parcourir'],
    ['Choose file', 'اختر ملف', 'Choisir un fichier'],
    ['Enter password', 'أدخل كلمة المرور', 'Entrez le mot de passe'],
    ['Protect', 'حماية', 'Protéger'],
    ['Unprotect', 'إلغاء الحماية', 'Désactiver la protection'],
    ['Files Exchange', 'تبادل الملفات', 'Échange de fichiers'],
    ['Folder', 'المجلد', 'Dossier'],
    ['Is Public', 'هل هو عام', 'Est public'],
    ['Incident', 'حادث', 'Incident'],
    ['detected', 'تم الكشف عنه', 'détecté'],
    ['Incidents', 'الحوادث', 'Incidents'],
    ['Security', 'الأمان', 'Sécurité'],
].reduce((p, [en, ar, fr]) => { p[en] = { ar, fr }; return p }, {});

// Tableau de suivi de la fermeture des dossiers fiscaux 2024
// جدول متابعة غلق الملفات الجبائية 2024
// Tableau de suivi de la fermeture des dossiers fiscaux 2024
// English: 
export function t(value: string) {
    const lang = SessionContext.lang;
    if (lang == 'en' || !MAP[value]) return value;
    return MAP[value][lang] || value;
    return {
        'active': 'نشط',
        'Users': 'المستخدمين',
        'Settings': 'الإعدادات',
        'Password': 'كلمة المرور',
        'Loading': 'جار التحميل',
        'Create': 'إنشاء',
        'Edit': 'تعديل',
        'Delete': 'حذف',
        'Save': 'حفظ',
        'Cancel': 'إلغاء',
        'Search': 'بحث',
        'Yes': 'نعم',
        'No': 'لا',
        'Add': 'إضافة',
        'Update': 'تحديث',
        'Status': 'الحالة',
        'Name': 'الاسم',
        'Name/Company Name': 'الاسم الجتماعي /الاسم و اللقب',
        'Roles': 'الصلاحيات',
        'Unique Identifier': 'الرقم الوحيد',
        'User added successfully': 'تمت إضافة المستخدم بنجاح',
        'User updated successfully': 'تم تحديث المستخدم بنجاح',
        'Employee': 'موظف',
        'Administration': 'ادارة التطبيق',
        'Admin': 'مدير',
        'Files': 'الملفات',
        'Procedures': 'الإجراءات',
        'Procedures Types': 'أنواع الإجراءات',
        'Procedure Type': 'نوع الإجراء',
        'Taxable Entity': 'المطالب بالضريبة',
        'Contact Person': 'الممثل القانوني',
        'Contact Persons': 'الممثل القانوني',
        'Taxable Entities': 'المطالببن بالضريبة',
        'Tax Identification Number': 'المعرف الجبائي',
        'Legal Status': 'الوضع القانوني',
        'Address': 'العنوان',
        'Procedure': 'الإجراء',
        'Contacts': 'الممثل القانوني',
        'Contact': 'الممثل القانوني',
        'National Identification Number': 'الرقم الوطني',
        'Phone': 'الهاتف',
        'Email': 'البريد الإلكتروني',
        'Postal Code': 'الرمز البريدي',
        'User': 'المستخدم',
        'User removed successfully': 'تم حذف المستخدم بنجاح',
        'Serial Number': 'الرقم التسلسلي',
        'Ordering Number': 'رقم الطلب',
        'Accepted Date': 'تاريخ القبول',
        'Closed Date': 'تاريخ الإغلاق',
        'Date': 'التاريخ',
        'pending': 'قيد الانتظار',
        'Result': 'النتيجة',
        'Share': 'مشاركة',
        'Shared by': 'مشاركة بواسطة',
        'to': 'إلى',
        'at': 'في',
        'Vat Code': 'رمز الضريبة',
        'Category Code': 'رمز الفئة',
        'Main Activity': 'النشاط الرئيسي',
        'Common Name': 'الاسم الشائع',
        'Activity': 'النشاط',
        'Secondary Activity': 'النشاط الثانوي',
        'Code': 'الرمز',
        'Owner': 'الموضف المسؤول عن فتح الاجراء',
        'Accept': 'قبول',
        'Close': 'إغلاق',
        'Are you sure you want to accept this procedure?': 'هل أنت متأكد أنك تريد قبول هذا الإجراء؟',
        'Please enter the status of the procedure on closing': 'الرجاء إدخال حالة الإجراء عند الإغلاق',
        'Procedure closed successfully': 'تم إغلاق الإجراء بنجاح',
        'Procedure accepted successfully': 'تم قبول الإجراء بنجاح',
        'Regulatory Time Limit': 'الحد الزمني التنظيمي',
        'days': 'أيام',
        'Office': 'المكتب',
        'Download': 'تحميل',
        'Access': 'الحقوق',
        'Preview': 'معاينة',
        'shared successfully': 'تمت المشاركة بنجاح',
        'Procedure Name': 'اسم الإجراء',
        'This element exists in the system': 'هذا العنصر موجود في النظام',
        'Contact Person Role': 'صفة الممثل القانوني',
        'open': 'مفتوح',
        'done': 'منجز',
        'rejected': 'مرفوض',
        'accepted': 'مقبول',
        'Occupation': 'المهنة',
        "Shares": "المشاركات",
        'Change Ownership': 'تغيير الملكية',
        'File': 'الملف',
        'Change': 'تغيير',
        'ownership changed successfully': 'تم تغيير الملكية بنجاح',
        'Remove': 'حذف',
        'Tracking tables': 'جداول المتابعة',
        'Inactive': 'غير نشط',
        'Active': 'نشط',
        'inactive': 'غير نشط',
        'Establishment': 'المؤسسة',
        'Establishments': 'المؤسسات',
        'Establishment Type': 'نوع المؤسسة',
        'Establishments Types': 'أنواع المؤسسات',
        'Department': 'القسم',
        'Departments': 'الأقسام',
        'Arrival Time': 'وقت الوصول',
        'Departure Time': 'وقت المغادرة',
        'Badge Number': 'رقم الشارة',
        'Identifier': 'المعرف',
        'Purpose': 'الغرض',
        'Visitors Log': 'سجل الزوار',
        'Maintenance Log': 'سجل الصيانة',
        'Equipments Log': 'سجل المعدات',
        'repair': 'إصلاح',
        'maintenance': 'صيانة',
        'Intervention Type': 'نوع التدخل',
        'Intervention Date': 'تاريخ التدخل',
        'Intervention Authorization': 'تفويض التدخل',
        'Equipment Name': 'اسم المعدة',
        'Nature and description of the intervention': 'طبيعة ووصف التدخل',
        'Technicians': 'الفنيين',
        'Equipment Log': 'سجل المعدات',
        'Technician': 'الفني',
        'Equipment': 'المعدة',
        'Equipment Type': 'نوع المعدة',
        'Equipment Reference': 'المرجع',
        'Equipment Model': 'الموديل',
        'Person ID': 'رقم الشخص',
        'Destination': 'الوجهة',
        'Date Time': 'التاريخ و الوقت',
        'entry': 'دخول',
        'exit': 'خروج',
    }[value] || value;
}